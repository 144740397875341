import React, { useContext, useEffect, useState } from "react"
import * as styles from "./index.module.scss"
import AddToCart from "./AddToCart"
import DefaultImage from "../../../images/default-store-image-bw.jpg"
import currencyFormat from "../../../utils/currencyFormat"
import { CartContext } from "../../../contexts/Cart"

const Product = ({
  product: {
    id,
    name,
    selling_price,
    compare_at_price,
    other_names,
    web_unit,
    image,
  },
  homePage = false,
}) => {
  const { label, label_plural, measure, measure_after_label } = web_unit || {}
  const {
    cart: { items },
  } = useContext(CartContext)

  const isLoading = !name && !selling_price

  const [amount, setAmount] = useState(1)
  const [unitAmount, setUnitAmount] = useState("")

  useEffect(() => {
    if (items.length === 0) return
    const found = items.find(item => item.id === id)
    if (found) {
      setAmount(found.amount || 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    if (Number(measure_after_label)) {
      setUnitAmount(
        `${amount === 1 ? label : label_plural} ${measure * amount}`,
      )
    } else {
      setUnitAmount(
        `${measure * amount} ${amount === 1 ? label : label_plural}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, label])
  return (
    <div
      className={
        homePage
          ? "col-sm-6 col-md-4 col-lg-4 col-xl-2"
          : `col-sm-6 col-lg-4 col-xl-3`
      }
    >
      <div className={styles.product_wrapper}>
        <div className={`${styles.product_img} ${isLoading ? "" : ""}`}>
          <img src={image || DefaultImage} alt={name} />
        </div>
        <div>
          <h4 className={isLoading ? styles.loading_title : ""}>{name}</h4>
          <p className={isLoading ? styles.loading_price : ""}>
            {isLoading ? "" : currencyFormat(selling_price * amount)}
            <br />
            <small>({unitAmount})</small>
          </p>
          <AddToCart
            id={id}
            name={name}
            amount={amount}
            setAmount={setAmount}
            unitAmount={unitAmount}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default Product
