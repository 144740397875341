import React, { useContext, useEffect, useState } from "react"
import * as styles from "./index.module.scss"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import { StoreContext } from "../../contexts/Store"
import ProductsList from "../../components/OrganicStore/ProductsList"
import Sidebar from "./_sidebar"
import SlidingMenu from "../../components/SlidingMenu"
import { navigate, Link } from "gatsby"
import Layout from "../../Layout"
import get from "lodash/get"
import {
  RiFileListLine,
  RiSearchLine,
  RiShoppingCartFill,
  RiShoppingCartLine,
  RiWhatsappFill,
  RiPhoneFill,
} from "react-icons/ri"
import { CartContext } from "../../contexts/Cart"
import isOnlineStoreOpen from "../../config/isOnlineStoreOpen"

const defaultPageTitle = "Organic Store"

const OrganicStore = ({ params, ...props }) => {
  const activeCategory = get(params, "index", "")
  const [pageTitle, setPt] = useState("Organic Store")
  const [type, setType] = useState("search")
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [searchStr, setSearchStr] = useState("")

  const {
    store: { products, categories },
    actions: { setFilteredProducts, setSearchString },
  } = useContext(StoreContext)

  const {
    cart: { items },
  } = useContext(CartContext)

  useEffect(() => {
    if (activeCategory) {
      const category = categories.find(cat => cat.slug === activeCategory)
      if (category) {
        setPt(category.name)
      } else {
        setPt(defaultPageTitle)
      }
    } else {
      setPt(defaultPageTitle)
    }
  }, [activeCategory, categories, products])

  const search = searchString => {
    if (!searchString) {
      setFilteredProducts(products)
      setSearchString("")
      return
    }

    setFilteredProducts(
      products.filter(product => {
        const nameAvailable =
          searchString &&
          product.name &&
          product.name.toLowerCase().includes(searchString.toLowerCase())
        const otherNamesAvailable =
          searchString &&
          product.other_names &&
          product.other_names.toLowerCase().includes(searchString.toLowerCase())
        return nameAvailable || otherNamesAvailable
      }),
    )
    setSearchString(searchString)
  }

  return (
    <Layout>
      <Seo title={pageTitle} />
      <Breadcrumbs title={pageTitle} />
      <section className={"py-5 my-4"}>
        <div className="container">
          {isOnlineStoreOpen ? (
            <div className="row">
              <div className="d-none d-md-block col-md-4 col-xl-3">
                <div className={styles.sidebar}>
                  <Sidebar activeCategory={activeCategory} search={search} />
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="row">
                  <div className="col-12 d-md-none mb-3">
                    <input
                      type="text"
                      placeholder={"search here..."}
                      className={`form-control d-inline-block ${styles.inline_search_input}`}
                      value={searchStr}
                      onChange={e => setSearchStr(e.target.value)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        search(searchStr)
                      }}
                    >
                      <RiSearchLine />
                    </button>
                  </div>
                  <ProductsList
                    activeCategory={activeCategory}
                    showCategoriesMobileMenu={() => {
                      setType("categories")
                      setMobileMenuOpen(true)
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <StoreClosed />
          )}
        </div>
      </section>

      <div className={"d-md-none"}>
        <SlidingMenu
          isActive={mobileMenuOpen}
          close={() => setMobileMenuOpen(false)}
        >
          <Sidebar
            activeCategory={activeCategory}
            search={search}
            type={type}
            className={"mt-5"}
            onClick={() => setMobileMenuOpen(false)}
          />
        </SlidingMenu>
        <ul className={styles.mobile_menu_buttons}>
          <li>
            <button
              className="btn"
              onClick={() => {
                setType("categories")
                setMobileMenuOpen(true)
              }}
            >
              <RiFileListLine />
              <span>Product categories</span>
            </button>
          </li>
          <li>
            <button
              className="btn"
              onClick={() => {
                setType("search")
                setMobileMenuOpen(true)
              }}
            >
              <RiSearchLine />
              <span>Search products</span>
            </button>
          </li>
          <li>
            <button
              className="btn"
              onClick={() => navigate("/cart")}
              disabled={items.length === 0}
            >
              {items.length === 0 ? (
                <RiShoppingCartLine />
              ) : (
                <RiShoppingCartFill />
              )}
              <span>
                Cart <small>({items.length} items)</small>
              </span>
            </button>
          </li>
        </ul>
      </div>
    </Layout>
  )
}
export default OrganicStore

const StoreClosed = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-sm-12 col-lg-8 text-center">
        <h2>We are enhancing Your E-commerce Shopping Experience</h2>
        <br />
        <p style={{ fontSize: "22px" }}>
          We are currently enhancing our e-commerce store to provide you with a
          better shopping experience.
        </p>

        <p style={{ fontSize: "22px" }}>
          You can still place orders through WhatsApp or by calling us:
        </p>
      </div>

      <div className="col-12" />

      <div className="col-lg-3 text-center">
        <h4 className={"mb-4"}>Ragama</h4>
        <a
          href={"https://wa.me/+94741350501"}
          className={"d-flex align-items-center justify-content-center"}
        >
          <RiWhatsappFill size={32} color={"#2AB23F"} />
          <p className={"mb-0 ml-2 h4"}>074 13 50 501</p>
        </a>
        <br />
        <a
          href={"tel:+94741350501"}
          className={"d-flex align-items-center justify-content-center"}
        >
          <RiPhoneFill size={32} color={"#2AB23F"} />
          <p className={"mb-0 ml-2 h4"}>074 13 50 501</p>
        </a>
      </div>
      <div className="col-lg-3 text-center">
        <h4 className={"mb-4"}>Nugegoda</h4>
        <a
          href={"https://wa.me/+94741350502"}
          className={"d-flex align-items-center justify-content-center"}
        >
          <RiWhatsappFill size={32} color={"#2AB23F"} />
          <p className={"mb-0 ml-2 h4"}>074 13 50 502</p>
        </a>
        <br />
        <a
          href={"tel:+94741350502"}
          className={"d-flex align-items-center justify-content-center"}
        >
          <RiPhoneFill size={32} color={"#2AB23F"} />
          <p className={"mb-0 ml-2 h4"}>074 13 50 502</p>
        </a>
      </div>
    </div>
  )
}
